export function scrollToTop(id) {
  window.lenis.stop()
  id = id ? id : 'kl-scroll-top'
  setTimeout(() => {
    document.getElementById(id).scrollIntoView({
      block: 'center',
      behavior: 'auto',
    })
    window.lenis.start()
  }, 0)
}
