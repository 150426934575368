<template>
  <div class="kl-menu" id="kl-menu" @mouseleave="closeMenu">
    <div class="kl-menu-wrap">
      <div class="kl-menu-icon" @click="openMenu">
        <i class="iconfont icon-menu"></i>
      </div>
      <div class="kl-logo" @click="navigateTo({ path: '/index' })">
        <img
          src="https://kelan5.oss-cn-hangzhou.aliyuncs.com/imgs/index/index.png"/>
        <span class="font-HarmonyOS_Sans_SC_Light"
          >| 东方微生态平衡祛斑美白专家</span
        >
      </div>
      <div class="kl-menu-list kl-menu-list-pc">
        <div class="kl-menu-hover-wrap">
          <div
            class="kl-menu-item"
            v-for="(item, index) in menu"
            :key="index"
            @mouseenter="handleMouseEnter(item, $event)"
            @click="navigateTo(item)"
          >
            <span class="font-HarmonyOS_Sans_SC_Medium">{{ item.name }}</span>
            <!-- 添加一个底部横线 -->
            <div class="underline"></div>
          </div>
        </div>
      </div>

      <div class="kl-search" @click="showSearch = true">
        <img src="@/assets/imgs/base/icon-search.png" />
      </div>
    </div>

    <transition name="slide-down">
      <div
        class="kl-menu-dtl"
        v-if="currentMenu?.children?.length > 0"
        :style="
          currentMenu.id === 3 ? {} : { paddingLeft: currentMenuLeft + 'px' }
        "
        :class="{
          hasChildren: currentMenu.id == 3,
          nochildren: currentMenu.id != 3,
        }"
      >
        <div
          class="kl-menu-item"
          :class="{ active: item.active }"
          v-for="(item, index) in currentMenu.children"
          :key="index"
        >
          <span
            class="font-HarmonyOS_Sans_SC_Light"
            @click="navigateTo(item)"
            >{{ item.name }}</span
          >
          <div
            class="kl-sub-menu-list"
            v-if="item.children && item.children.length > 0"
          >
            <p
              v-for="(mtem, mindex) in item.children"
              :key="mindex"
              @click="navigateTo(mtem)"
              class="font-HarmonyOS_Sans_SC_Light"
            >
              {{ mtem.name }}
            </p>
          </div>
        </div>
      </div>
    </transition>

    <transition name="expand">
      <div v-if="showSearch" class="search-overlay" @click.self="showSearch = false">
        <div class="search-box">
          <p>
            <img
              class="kl-logo"
              src="https://kelan5.oss-cn-hangzhou.aliyuncs.com/imgs/index/index.png"
              alt=""
              @click="navigateTo({ path: '/index' })"
            />
            <span class="font-HarmonyOS_Sans_SC_Light"
              >| 东方微生态平衡祛斑美白专家</span
            >
          </p>
          <div>
            <input
              type="text"
              placeholder="搜索"
              @keyup.enter="onEnter"
              @blur="onEnter"
              v-model="searchQuery"
            />
            <span @click="showSearch = false">✖</span>
          </div>
        </div>
      </div>
    </transition>

    <!--h5显示-->
    <transition name="fade">
      <div
        class="kl-menu-list"
        v-if="show"
        @click="closeMenuSlide"
        @touchmove.prevent
      >
        <div
          class="kl-menu-wrap"
          :class="{ 'kl-menu-open': menuShow }"
          @click.stop
        >
          <div
            class="kl-menu-item"
            :class="{ active: item.active }"
            v-for="(item, index) in menu"
            :key="index"
            @click="navigateTo(item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ref, reactive, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { scrollToTop } from '@/utils'
import menuData from '@/assets/json/menu.json'

const currentMenu = ref(null)
const menu = reactive(menuData.menu) // 使用 reactive 使 menu 响应式
const currentMenuLeft = ref(0)
const showSearch = ref(false)
const searchQuery = ref('')
const show = ref(false)
const menuShow = ref(false)

// 获取路由实例
const router = useRouter()
const route = useRoute()

// 路由变化时处理菜单项激活状态
watchEffect(() => {
  menu.forEach((item) => {
    item.active = route.path.includes(item.path)
  })
})
// 导航方法
function navigateTo(item) {
  const { path, to } = item
  if (path) {
    router.push({ path, query: { to } })
    let htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth
    if (htmlWidth > 1440) {
      closeMenu()
    } else {
      closeMenuSlide()
    }

    scrollToTop()
  }
}

// 鼠标移入事件
function handleMouseEnter(item, event) {
  currentMenu.value = item
  currentMenuLeft.value = event.target.getBoundingClientRect().left // 获取元素左侧的坐标
}

function closeMenu() {
  currentMenu.value = {}
}
function onEnter() {
  showSearch.value = false
  router.push({ path: '/skin', query: { searchVal: searchQuery.value } })
}
function openMenu() {
  show.value = true
  setTimeout(() => {
    menuShow.value = true
  }, 10)
}
function closeMenuSlide() {
  menuShow.value = false
  setTimeout(() => {
    show.value = false
  }, 0)
}
</script>
<style scoped lang="scss">
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
/* Slide down transition */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease;
}

.slide-down-enter-from,
.slide-down-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
  overflow: hidden;
}

.slide-down-enter-to,
.slide-down-leave-from {
  max-height: 500px; /* 设置一个足够大的高度 */
  transform: translateY(0);
}

.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
</style>
