<template>
  <div class="kl-slide-menu">
    <div>
      <div class="qrcode">
        <img class="img-qrcode"
             src="https://kelan5.oss-cn-hangzhou.aliyuncs.com/cust_qrcode.png" />
      </div>
      <img src="@/assets/imgs/base/icon-wx.png" />
    </div>
    <div id="kl-udesk-connect-btn">
      <img src="@/assets/imgs/base/icon-tel.png" />
    </div>
    <div @click="toTop">
      <img src="@/assets/imgs/base/icon-arrow-up.png" />
    </div>
  </div>
</template>
<script>
import { scrollToTop } from '@/utils'
export default {
  mounted() {},
  methods: {
    toTop() {
      scrollToTop()
    },
  },
}
</script>
<style lang="scss" scoped>
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
</style>
