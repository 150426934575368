import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/index',
      name: 'Index',
      component: () =>
        import(/* webpackChunkName: "Index" */ '../page/index/index.vue'),
    },
    {
      path: '/star',
      name: 'Star',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/star/index.vue'),
    },
    {
      path: '/dynamics',
      name: 'Dynamics',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/dynamics/index.vue'),
    },
    {
      path: '/news',
      name: 'News',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/news/index.vue'),
    },
    {
      path: '/explore',
      name: 'Explore',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/explore/index.vue'),
    },
    {
      path: '/join',
      name: 'Join',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/join/index.vue'),
    },
    {
      path: '/microecology',
      name: 'Microecology',
      component: () =>
        import(
          /* webpackChunkName: "About" */ '../page/microecology/index.vue'
        ),
    },
    {
      path: '/skin/:searchVal?',
      name: 'Skin',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/skin/index.vue'),
    },
    {
      path: '/prdt',
      name: 'Prdt',
      component: () =>
        import(/* webpackChunkName: "About" */ '../page/prdt/index.vue'),
    },
    {
      path: '/',
      redirect: '/index',
    },
  ],
})

export default router
