<template>
  <div class="kl-app">
    <div id="kl-scroll-top"></div>
    <kmMenu></kmMenu>
    <router-view />
    <kmSlideMenu></kmSlideMenu>
    <kmFooter></kmFooter>
  </div>
</template>
<script>
import kmMenu from './components/kmMenu/index'
import kmFooter from './components/kmFooter/index'
export default {
  components: { kmMenu, kmFooter },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>
<style lang="scss">
@import './font.scss';
@import './assets/css/var.scss';
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: PingFang-SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.kl-app {
  width: 100%;
  min-height: 100vh;
  color: #333333;
  font-size: 14px;
}
</style>
