<template>
  <div class="km-size">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '1260px',
    },
    padding: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.km-size {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
}
@import './pc.scss';
@import './ipad.scss';
@import './mobile.scss';
</style>
